<template>
  <div
    class="w-full min-h-full bg-[#f0f0f0] py-5"
    :class="loader ? 'items-center justify-center' : ''"
  >
    <!---------------------------- loader ----------------------->
    <template class="w-full h-full" v-if="loader">
      <div class="w-336 mx-auto px-5">
        <div class="p-5 px-8 flex justify-center gap-x-8">
          <div
            class="
              bg-dokBlue-light
              w-8
              h-8
              rounded-full
              animate-bounce
              blue-circle
            "
          ></div>
          <div
            class="
              bg-rosDok-ultra
              w-8
              h-8
              rounded-full
              animate-bounce
              red-circle
            "
          ></div>
          <div
            class="
              bg-dokBlue-ultra
              w-8
              h-8
              rounded-full
              animate-bounce
              green-circle
            "
          ></div>
        </div>
      </div>
    </template>

    <div v-if="!loader" class="container">
      <!---------------------------- header ----------------------->
      <div
        class="
          p-4
          mb-3
          flex flex-row
          items-center
          justify-center
          lg:justify-between
          rounded-lg
          shadow
          bg-white
        "
      >
        <h1
          class="
            hidden
            md:inline
            text-2xl
            font-semibold
            text-gray-700
            font-EffraR
            flex-1
          "
        >
          <i class="fa fa-ticket-alt"></i> Tickets
        </h1>
        <div class="flex flex-row items-center gap-3 md:gap-3 mr-5">
          <button
            @click="
              $performTracking({
                data: {
                  id: $auth.getUserId(),
                  route: 'SUPPORT',
                  action: 'NEW',
                  data: {},
                },
              });
              modal = true;
            "
            style="color: #1d55af"
            class="
              font-EffraR
              bg-dokBlue-ultra
              py-3
              px-5
              text-white
              border-0
              rounded
              cursor-pointer
              truncate
            "
            aria-label="Nouveau Ticket"
          >
            <i class="fa fa-ticket-alt"></i>&nbsp;&nbsp;Nouveau Ticket
          </button>
        </div>
      </div>

      <!---------------------------- data ----------------------->
      <div class="rounded-cu mt-base" v-if="tickets.length > 0">
        <!---------------------------- tickets ----------------------->
        <div class="w-full mb-5 bg-white shadow-xl">
          <div
            @click="getTicket(ticket._id)"
            class="
              flex
              items-center
              hover:bg-gray-100
              px-5
              py-3
              border-0 border-b border-solid border-gray-300
            "
            style="cursor: pointer"
            v-for="(ticket, index) in tickets"
            :key="index"
          >
            <i class="far fa-comment-dots text-4xl mx-3"></i>
            <div class="flex flex-col flex-1">
              <div class="flex justify-between">
                <h1
                  class="font-semibold text-left text-xl font-EffraR"
                  style="color: #2250be"
                >
                  {{ ticket.sujet ? ticket.sujet : "Sujet vide" }}
                </h1>
                <div
                  v-if="ticket.status == 3"
                  class="rounded-full bg-red-700 p-2 w-2 h-2"
                  title="Non Résolu"
                ></div>
                <div
                  v-if="ticket.status == 2"
                  class="rounded-full bg-green-700 p-2 w-2 h-2"
                  title="Résolu"
                ></div>
                <div
                  v-if="ticket.status == 1"
                  title="En cours"
                  :class="
                    ticket.reply == 'S'
                      ? 'rounded-full relative bg-dokBlue-ultra  p-2 w-2 h-2 animate-ping'
                      : 'rounded-full bg-dokBlue-ultra p-2 w-2 h-2'
                  "
                ></div>
              </div>
              <span class="text-gray-700 font-semibold">{{ ticket.type }}</span>
              <span class="text-gray-700 font-semibold">{{
                Math.abs(
                  new Date(ticket.updated_at).getDay() -
                    new Date(Date.now()).getDay()
                ) < 2 &&
                new Date(ticket.updated_at).getMonth() ==
                  new Date(Date.now()).getMonth()
                  ? moment()(ticket.updated_at).fromNow()
                  : moment()(ticket.updated_at).format("DD - MMMM - YY")
              }}</span>
            </div>
          </div>
        </div>

        <!---------------------------- pagination ----------------------->
        <div
          v-show="page > 1"
          class="py-5 flex justify-center items-center shadow-xl bg-white"
        >
          <div class="flex flex-row">
            <div>
              <a
                href="javascript:void(0)"
                @click="
                  page--;
                  loadTickets();
                "
                :class="page == 1 ? 'disable ' : ''"
                class="
                  px-4
                  py-4
                  mx-5
                  border-0
                  shadow-sm
                  text-nblue
                  rounded-full
                  cursor-pointer
                  hover:shadow-lg hover:text-gray-100 hover:bg-nblue
                "
                ><i class="fa fa-angle-left" aria-hidden="true"></i
              ></a>
            </div>
            <div v-for="item in pages" :key="item">
              <a
                href="javascript:void(0)"
                @click="
                  page = item;
                  loadTickets();
                "
                :class="
                  page == item
                    ? 'shadow-lg text-gray-100 bg-dokBlue-ultra disable'
                    : 'text-dokBlue-ultra'
                "
                class="
                  px-4
                  py-4
                  mx-1
                  border-0
                  shadow-sm
                  rounded-full
                  cursor-pointer
                  hover:shadow-lg hover:text-gray-100 hover:bg-nblue
                "
                >{{ item }}</a
              >
            </div>
            <div>
              <a
                href="javascript:void(0)"
                @click="
                  page++;
                  loadTickets();
                "
                :class="page == pages || pages == 0 ? 'disable ' : ''"
                class="
                  px-4
                  py-4
                  mx-5
                  border-0
                  shadow-sm
                  text-nblue
                  rounded-full
                  cursor-pointer
                  hover:shadow-lg hover:text-gray-100 hover:bg-nblue
                "
                ><i class="fa fa-angle-right" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>

      <!---------------------------- null data ----------------------->
      <div
        v-if="tickets.length == 0"
        class="bg-white mx-auto w-full text-center"
      >
        <img
          src="/svg/null_data.svg"
          class="max-h-300 xl:max-h-500"
          alt="empty"
        />
      </div>
    </div>

    <!---------------------------- modal ----------------------->
    <modal
      :title="'Nouveau Ticket'"
      :is-active="modal"
      :exit-modal="
        () => {
          this.modal = false;
        }
      "
    >
      <div class="m-10 flex flex-col gap-y-8">
        <div class="">
          <label class="font-EffraR">Sujet</label>
          <input
            id="sujet"
            v-model="formData.sujet"
            class="
              mt-1
              text-base
              font-EffraR
              resize-none
              bg-transparent
              border border-solid border-gray-300
              py-2
              px-1
              rounded-cu
              w-full
            "
            type="text"
            placeholder="Sujet"
          />
        </div>
        <div class="">
          <label class="font-EffraR"> Type :</label>
          <select
            v-model="formData.type"
            id="type"
            class="
              mt-1
              w-full
              text-base
              font-EffraR
              bg-white
              border-gray-300
              py-2
              rounded-cu
            "
          >
            <option v-for="(type, index) in types" :key="index" :value="type">
              {{ type }}
            </option>
          </select>
        </div>
        <div class="">
          <label class="font-EffraR mt-5"> Message :</label>
          <textarea
            v-model="messageData.message"
            rows="6"
            placeholder="Veuillez détailler votre problème ou question ..."
            class="
              resize-none
              bg-transparent
              border border-solid border-gray-300
              py-3
              px-2
              rounded-cu
              w-full
              text-base
              mt-1
              font-EffraR
            "
            aria-label="Veuillez détailler votre problème ou question ..."
          ></textarea>
        </div>

        <button
          @click="saveTicket"
          class="
            font-EffraR
            bg-dokBlue-ultra
            py-3
            px-5
            text-white
            border-0
            rounded
            cursor-pointer
            truncate
          "
          aria-label="Envoyer"
        >
          <i class="fa fa-paper-plane"></i>&nbsp;&nbsp;Envoyer
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import moment from "moment";
const modal = () => import("@/views/global-components/modal.vue");
const loader = () => import("@/views/global-components/loader");

export default {
  data() {
    return {
      page: 1,
      pages: 1,
      tickets: null,
      types: [],
      formData: { sujet: "", type: "" },
      modal: false,
      messageData: { replyTo: "" },
      loader: true,
    };
  },
  components: { modal, loader },

  async mounted() {
    this.loadTickets();
    this.types = await this.$store.dispatch("support/GET_TYPE");
    this.formData.type = this.types[0];
    //await this.$store.dispatch("support/DEADLINE");
  },

  methods: {
    moment() {
      moment.locale("fr");
      return moment;
    },

    async loadTickets() {
      try {
        this.loader = true;
        const { ok, data, pages } = await this.$store.dispatch(
          "support/GET_LIST_TICKET",
          {
            doctorId: this.$auth.getUserInfo()._id,
            page: this.page,
          }
        );
        if (ok) {
          this.data = data;
          this.nbPages = Math.ceil(data.length / this.limitPage);
          this.tickets = data;
          this.pages = pages;
        }
      } finally {
        this.loader = false;
      }
    },

    async saveTicket() {
      try {
        this.modal = true;
        const { data, ok } = await this.$store.dispatch("support/STORE", {
          formData: this.formData,
        });
        if (ok) {
          this.messageData.ticketId = data._id;
          await this.$store.dispatch("support/STORE_MESSAGE", {
            formData: this.messageData,
          });
          this.tickets.unshift(data);
          this.messageData.message = "";
          this.formData.sujet = "";
          this.$performTracking({
            data: {
              id: this.$auth.getUserId(),
              route: "SUPPORT",
              action: "CREATE",
              data: {},
            },
          });
        }
      } finally {
        this.modal = false;
      }
    },

    getTicket(id) {
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "SUPPORT",
          action: "SHOW",
          data: {},
        },
      });
      let role = this.$auth.getUserInfo().role;
      if (role == "PHARMACY" || role == "PHARMACY-MEMBER") {
        this.$router.push("/pharmacy/support/" + id);
      } else if (role == "LABORATORY" || role == "LABORATORY-MEMBER") {
        this.$router.push("/laboratory/support/" + id);
      } else {
        this.$router.push("/support/" + id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.disable {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
